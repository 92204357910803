import React from 'react';
import styles from './Welcome2.module.scss'
import { Container, Nav, Navbar } from 'react-bootstrap';
import guatemala1 from './images/guatemala1.png';
import guatemala2 from './images/guatemala2.png';
import guatemala3 from './images/guatemala3.png';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
import Footer from './components/welcomeFooter'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


const products = {
    initial: { y: -20, opacity: 0 },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.7,
            ease: [0.6, -0.05, 0.01, 0.99],
        },
    },
};

function getRandomImage (){
    var theRandomNumber = Math.floor(Math.random() * 3) + 1;
    switch (theRandomNumber){
        case 1:return guatemala1
        case 2:return guatemala2
        case 3:return guatemala3
    }
}

export const Welcome2 = (props) => (
    <motion.div exit={{ opacity: 0 }}>
        <img
            className={styles.coverImage}
            alt="Mountain Landscape"
            src={getRandomImage()}
        >
        </img>
        <div className={styles.coverContent}>
            <Link className={styles.header} to="/info">
                lara j-p
            </Link>
            <motion.div
                variants={products}
                className="text-gray-700 body-font"
            >
                <div className={styles.BtnWrap}>
                    <Container>
                        <Row className='justify-content-md-center'>
                            <Col sm={6} md={5} lg={4}>
                                <Link className={styles.Button} to="/Gallery">
                                    gallery
                                </Link>
                            </Col>
                            <Col sm={6} md={5} lg={4}>
                                <Link className={styles.Button} to="/packages">
                                    packages
                                </Link>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </motion.div>
            <Footer/>
        </div>
    </motion.div>
)
