import React from 'react';
import styles from './Videos.module.scss'
import Container from 'react-bootstrap/Container';
import ReactPlayer from "react-player/youtube"

export const Videos = (props) => (
    <Container  className={styles.videocontainer}>
    <div className={styles.videos}>
        <ReactPlayer
            url="https://www.youtube.com/watch?v=DzEjxIY94-U"
        />
    </div>
    <div className={styles.videos}>
        <ReactPlayer
            url="https://www.youtube.com/watch?v=-QAlm67RLt0"
        />
    </div>
    <div className={styles.videos}>
        <ReactPlayer
            url="https://www.youtube.com/watch?v=4R4V5g4aAEo"
        />
    </div>
    </Container>
)


