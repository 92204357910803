import React from "react";
import { motion } from "framer-motion";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Lara from "./images/Medium/Lara.png";
import styles from "./Info.module.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import ListGroup from "react-bootstrap/ListGroup";

const products = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

export const Info = () => (
  <motion.div exit={{ opacity: 0 }}>
    <motion.section variants={products} className="text-gray-700 body-font">
      <div className={styles.container}></div>
      <Row>
        <Col sm={6} md={6} lg={4}>
          <div className={styles.photocontainer}>
            <img className={styles.photo} src={Lara}></img>
          </div>
        </Col>
        <Col>
          <h1 className={styles.title1}>lara j-p</h1>
          <h1 className={styles.title2}>photography</h1>
        </Col>
      </Row>
      <div className={styles.container}>
        <div className={styles.text}>
          Hi my name is Lara, I am a 20 year old photographer based in London
          and I specialise in portrait photography, providing professional
          headshots.
        </div>
        <div className={styles.text}>
          Please use the form below to contact me for inquiries.
        </div>
      </div>
      <div></div>
      <div>
        <h1 className={styles.contactMe}>contact me</h1>
        <Container className={styles.Form}>
          <Form action="https://apps.coodash.com/feedback/lara" method="post">
            <Form.Group>
              <Row>
                <Col>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    required
                    name="fname"
                    type="text"
                    placeholder="Enter first name"
                  />
                </Col>
                <Col>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    required
                    name="lname"
                    type="text"
                    placeholder="Enter last name"
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                required
                name="email"
                type="email"
                placeholder="Enter email"
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <Form.Control required name="message" as="textarea" rows={3} />
            </Form.Group>
            <Form.Control
              name="redirect"
              type="hidden"
              value="http://larajp.com/#/Thankyou"
            />
            <Button className={styles.button} type="submit">
              Send
            </Button>
          </Form>
        </Container>
      </div>
    </motion.section>
  </motion.div>
);
