import m_charmian1 from "./images/Medium/charmian1.png";
import m_charmian4 from "./images/Medium/charmian4.png";
import m_charmian5 from "./images/Medium/charmian5.png";
import m_charmian6 from "./images/Medium/charmian6.png";
import m_charmian7 from "./images/Medium/charmian7.png";
import m_charmian8 from "./images/Medium/charmian8.png";
import m_charmian9 from "./images/Medium/charmian9.png";
import m_charmian10 from "./images/Medium/charmian10.png";
import m_ellie1 from "./images/Medium/ellie1.png";
import m_ellie2 from "./images/Medium/ellie2.png";
import m_ellie3 from "./images/Medium/ellie3.png";
import m_Lara2 from "./images/Medium/Lara2.png";
import m_mum1 from "./images/Medium/mum1.png";
import m_mum2 from "./images/Medium/mum2.png";
import m_annabel1 from "./images/Medium/annabel1.png";
import m_annabel2 from "./images/Medium/annabel2.png";
import m_kate1 from "./images/Medium/kate1.png";
import m_tabby1 from "./images/Medium/tabby1.png";
import m_sophia1 from "./images/Medium/sophia1.png";
import m_hannah1 from "./images/Medium/hannah1.png"
import m_hannah2 from "./images/Medium/hannah2.png"
import m_hannah3 from "./images/Medium/hannah3.png"
import m_hannah5 from "./images/Medium/hannah5.png"
import m_hannah6 from "./images/Medium/hannah6.png"
import m_hannah8 from "./images/Medium/hannah8.png"
import m_hannah9 from "./images/Medium/hannah9.png"
import m_hannah10 from "./images/Medium/hannah10.png"
import m_hannah12 from "./images/Medium/hannah12.png"
import m_hannah13 from "./images/Medium/hannah13.png"
import m_hannah14 from "./images/Medium/hannah14.png"
import m_hannah15 from "./images/Medium/hannah15.png"
import m_John1 from "./images/Medium/John1.png"
import m_John2 from "./images/Medium/John2.png"
import m_John3 from "./images/Medium/John3.png"
import m_Sam1 from "./images/Medium/Sam1.png"
import m_Sam2 from "./images/Medium/Sam2.png"
import luciana1 from "./images/luciana1.png"
import luciana2 from "./images/luciana2.png"
import luciana3 from "./images/luciana3.png"
import luciana4 from "./images/luciana4.png"
import m_johnsam2 from "./images/Medium/johnsam2.png"
import m_johnsam3 from "./images/Medium/johnsam3.png"

import l_charmian1 from "./images/Large/charmian1.png";
import l_charmian4 from "./images/Large/charmian4.png";
import l_charmian5 from "./images/Large/charmian5.png";
import l_charmian6 from "./images/Large/charmian6.png";
import l_charmian7 from "./images/Large/charmian7.png";
import l_charmian8 from "./images/Large/charmian8.png";
import l_charmian9 from "./images/Large/charmian9.png";
import l_charmian10 from "./images/Large/charmian10.png";
import l_ellie1 from "./images/Large/ellie1.png";
import l_ellie2 from "./images/Large/ellie2.png";
import l_ellie3 from "./images/Large/ellie3.png";
import l_Lara2 from "./images/Large/Lara2.png";
import l_mum1 from "./images/Large/mum1.png";
import l_mum2 from "./images/Large/mum2.png";
import l_annabel1 from "./images/Large/annabel1.png";
import l_annabel2 from "./images/Large/annabel2.png";
import l_kate1 from "./images/Large/kate1.png";
import l_tabby1 from "./images/Large/tabby1.png";
import l_sophia1 from "./images/Large/sophia1.png";
import l_hannah1 from "./images/Large/hannah1.png"
import l_hannah2 from "./images/Large/hannah2.png"
import l_hannah3 from "./images/Large/hannah3.png"
import l_hannah5 from "./images/Large/hannah5.png"
import l_hannah6 from "./images/Large/hannah6.png"
import l_hannah8 from "./images/Large/hannah8.png"
import l_hannah9 from "./images/Large/hannah9.png"
import l_hannah10 from "./images/Large/hannah10.png"
import l_hannah12 from "./images/Large/hannah12.png"
import l_hannah13 from "./images/Large/hannah13.png"
import l_hannah14 from "./images/Large/hannah14.png"
import l_hannah15 from "./images/Large/hannah15.png"
import l_John1 from "./images/Large/John1.png"
import l_John2 from "./images/Large/John2.png"
import l_John3 from "./images/Large/John3.png"
import l_Sam1 from "./images/Large/Sam1.png"
import l_Sam2 from "./images/Large/Sam2.png"
import l_johnsam1 from "./images/Large/johnsam1.png"
import l_johnsam2 from "./images/Large/johnsam2.png"
import l_johnsam3 from "./images/Large/johnsam3.png"

export const imageData = [
  {
    m_image: m_hannah2,
    l_image: l_hannah2,
    title: "Hannah",
    subtitle: "Kings Cross",
  },
  {
    m_image: m_charmian10,
    l_image: l_charmian10,
    title: "Charmian",
    subtitle: "Barbican Centre",
  },
  {
    m_image: luciana2,
    l_image: luciana2,
    title: "Luciana",
    subtitle: "Dulwich",
  },
  {
    m_image: m_hannah8,
    l_image: l_hannah8,
    title: "Hannah",
    subtitle: "Kings Cross",
  },

  {
    m_image: m_John1,
    l_image: l_John1,
    title: "John",
    subtitle: "Barbican Centre",
  },

  {
    m_image: m_charmian9,
    l_image: l_charmian9,
    title: "Charmian",
    subtitle: "Barbican Centre",
  },
  {
    m_image: m_hannah9,
    l_image: l_hannah9,
    title: "Hannah",
    subtitle: "Kings Cross",
  },
  {
    m_image: m_Sam1,
    l_image: l_Sam1,
    title: "Sam",
    subtitle: "Barbican Centre",
  },
  {
    m_image: luciana3,
    l_image: luciana3,
    title: "Luciana",
    subtitle: "Dulwich",
  },


  {
    m_image: m_annabel1,
    l_image: l_annabel1,
    title: "Annabel",
    subtitle: "Little Venice",
  },
  {
    m_image: m_Lara2,
    l_image: l_Lara2,
    title: "Lara",
    subtitle: "Studio",
  },
  {
    m_image: m_hannah3,
    l_image: l_hannah3,
    title: "Hannah",
    subtitle: "Kings Cross",
  },

  {
    m_image: m_hannah6,
    l_image: l_hannah6,
    title: "Hannah",
    subtitle: "Kings Cross",
  },
  {
    m_image: m_charmian6,
    l_image: l_charmian6,
    title: "Charmian",
    subtitle: "Barbican Centre",
  },
  {
    m_image: luciana1,
    l_image: luciana1,
    title: "Luciana",
    subtitle: "Dulwich",
  },
  {
    m_image: m_Sam2,
    l_image: l_Sam2,
    title: "Sam",
    subtitle: "Barbican Centre",
  },
  {
    m_image: m_hannah10,
    l_image: l_hannah10,
    title: "Hannah",
    subtitle: "Kings Cross",
  },
  {
    m_image: m_charmian7,
    l_image: l_charmian7,
    title: "Charmian",
    subtitle: "Barbican Centre",
  },
  
  {
    m_image: m_hannah1,
    l_image: l_hannah1,
    title: "Hannah",
    subtitle: "Kings Cross",
  },
  {
    m_image: m_John3,
    l_image: l_John3,
    title: "John",
    subtitle: "Barbican Centre",
  },
  {
    m_image: m_mum2,
    l_image: l_mum2,
    title: "Susanna",
    subtitle: "Studio",
  },
  {
    m_image: m_hannah12,
    l_image: l_hannah12,
    title: "Hannah",
    subtitle: "Kings Cross",
  },
  {
    m_image: luciana4,
    l_image: luciana4,
    title: "Luciana",
    subtitle: "Dulwich",
  },
  {
    m_image: m_charmian5,
    l_image: l_charmian5,
    title: "Charmian",
    subtitle: "Barbican Centre",
  },

  {
    m_image: m_annabel2,
    l_image: l_annabel2,
    title: "Annabel",
    subtitle: "The Thames",
  },

  {
    m_image: m_ellie3,
    l_image: l_ellie3,
    title: "Ellie",
    subtitle: "Studio",
  },
  {
    m_image: m_hannah5,
    l_image: l_hannah5,
    title: "Hannah",
    subtitle: "Kings Cross",
  },
  {
    m_image: m_ellie2,
    l_image: l_ellie2,
    title: "Ellie",
    subtitle: "Studio",
  },
  {
    m_image: m_hannah14,
    l_image: l_hannah14,
    title: "Hannah",
    subtitle: "Kings Cross",
  },
  {
    m_image: m_charmian8,
    l_image: l_charmian8,
    title: "Charmian",
    subtitle: "Barbican Centre",
  },
  {
    m_image: m_ellie1,
    l_image: l_ellie1,
    title: "Ellie",
    subtitle: "Studio",
  },
  {
    m_image: m_John2,
    l_image: l_John2,
    title: "John",
    subtitle: "Barbican Centre",
  },
  {
    m_image: m_hannah15,
    l_image: l_hannah15,
    title: "Hannah",
    subtitle: "Kings Cross",
  },


  {
    m_image: m_mum1,
    l_image: l_mum1,
    title: "Susanna",
    subtitle: "Studio",
  },









  // {
  //   m_image: m_johnsam1,
  //   l_image: l_johnsam1,
  //   title: "John and Sam",
  //   subtitle: "Barbican Centre",
  // },

  // {
  //   m_image: m_johnsam2,
  //   l_image: l_johnsam2,
  //   title: "John and Sam",
  //   subtitle: "Barbican Centre",
  // },
  
  // {
  //   m_image: m_johnsam3,
  //   l_image: l_johnsam3,
  //   title: "John and Sam",
  //   subtitle: "Barbican Centre",
  // },



];

export function getImages() {
  const res = {};
  for (var f = 0; f < imageData.length; f++) {
    const link = f + "-" + imageData[f].title
    res[link] = {...imageData[f], link}
  }
  return res;
}

export function getFirst() {
  const f = 0
  return f + "-" + imageData[f].title
}

export function getLinks() {
  const images = Object.values(getImages())
  const res = {};
  for (var f = 0; f < images.length; f++) {
    var prev = null;
    var next = null;
    if (f === 0) {
      prev = images[images.length - 1].link;
    } else {
      prev = images[f - 1].link;
    }
    if (f === images.length - 1) {
      next = images[0].link;
    } else {
      next = images[f + 1].link;
    }
    res[images[f].link] = { prev, next };
  }
  return res;
}
