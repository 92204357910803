import React, { useState } from "react";
import {
  Nav,
  Navbar,
  Form,
  FormControl,
  NavDropdown,
  Button,
  Container,
} from "react-bootstrap";
import styles from "./NavigationBar.module.scss";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";

export const NavigationBar = () => {
  const [show, setShow] = useState(false);

  function handleShow() {
    setShow(true);
  }

  function closeModal() {
    setShow(false)
  }
  return (
    <div className={styles.navbar1}>
      <Navbar expand="sm">
        <a class="navbar-brand" href="#">
          {" "}
          <Link className={styles.name} to="/Info">
             lara j-p 
          </Link>
        </a>
      </Navbar>

      <div className={styles.navbar}>
        <Navbar expand="sm">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav classNameclassName="ml-auto">
              <Nav.Item>
                <Nav.Link className={styles.link} as={Link} to="/">
                  {" "}
                  home{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className={styles.link} as={Link} to="/gallery">
                  {" "}
                  gallery{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className={styles.link} as={Link} to="/Packages">
                  {" "}
                  packages{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className={styles.link} as={Link} to="/Info">
                  {" "}
                  contact{" "}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className={styles.socialsContainer}>

        <Navbar>
          <Nav.Item>
            <Nav.Link
              className={styles.SocialIconLink}
              href="//www.instagram.com/lara.cjohnsonp"
              target="_blank"
              aria-label="Instagram"
            >
              <GrInstagram />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={styles.SocialIconLink}
              href="//www.twitter.com/laraj_p"
              target="_blank"
              aria-label="Twitter"
            >
              <FaTwitter />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={styles.SocialIconLink}
              href="//www.linkedin.com/in/lara-johnson-perkins-054989209"
              target="_blank"
              aria-label="Instagram"
            >
              <FaLinkedin />
            </Nav.Link>
          </Nav.Item>
        </Navbar>
      </div>
      <Navbar className={styles.Modal}>
        <AiOutlineMenu className={styles.Menu} onClick={() => handleShow()} />
        <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <Link className={styles.name} to="/Info">
                lara j-p
              </Link>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className={styles.menuItems}>
              <Link onClick={closeModal} className={styles.link1} as={Link} to="/">
                home
              </Link>
              <Link onClick={closeModal} className={styles.link1} as={Link} to="/gallery">
                gallery
              </Link>
              <Link onClick={closeModal} className={styles.link1} as={Link} to="/Packages">
                packages
              </Link>
              <Link onClick={closeModal} className={styles.link1} as={Link} to="/Info">
                contact
              </Link>
            </Container>
            <div className={styles.footer}></div>
          </Modal.Body>
          <div className={styles.footer}>
            <div className={styles.Wrap}>
              <div>
                <Container className={styles.footerContainer}>
                  <a
                    className={styles.SocialIconLink}
                    href="//www.instagram.com/lara.cjohnsonp"
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <GrInstagram />
                  </a>
                  <a
                    className={styles.SocialIconLink}
                    href="//www.twitter.com/laraj_p"
                    target="_blank"
                    aria-label="Twitter"
                  >
                    <FaTwitter />
                  </a>
                  <a
                    className={styles.SocialIconLink}
                    href="//www.linkedin.com/in/lara-johnson-perkins-054989209"
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <FaLinkedin />
                  </a>
                </Container>
              </div>
            </div>
          </div>
        </Modal>
      </Navbar>
    </div>
  );
};
