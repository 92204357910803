import React from "react";
import styles from "./Images.module.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill } from "react-icons/bs";
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";

import { getImages, getLinks } from "./ImageData";

export function Images() {
    let { id } = useParams();

    const imageData = getImages();
    const links = getLinks();

    function getImage(id) {
        return imageData[id].l_image;
    }
    function getPrevious(id) {
        return links[id].prev;
    }
    function getNext(id) {
        return links[id].next;
    }
    function getTitle(id) {
        return imageData[id].title;
    }
    function getSubtitle(id) {
        return imageData[id].subtitle;
    }
    return (
        <div>
            <div className={styles.div}>
                <Link className={styles.back} to={"/gallery/"}>
                    back
                </Link>
            </div>
            <Row className={styles.Row}>
                <Col className={styles.colLeft}>
                    <Link
                        className={styles.leftArrow}
                        to={getPrevious(id)}
                    >
                        <BsFillArrowLeftCircleFill className={styles.arrows} />
                    </Link>
                </Col>
                <Col className={styles.fullPage}>
                    <img className={styles.image} src={getImage(id)}></img>

                        <div className={styles.titleText}>{getTitle(id)}</div>
                        {" "}
                        <div className={styles.subtitleText}>
                            {getSubtitle(id)}
                        </div>
                </Col>
                <Col className={styles.colRight}>
                    <Link
                        className={styles.rightArrow}
                        to={getNext(id)}
                    >
                        <BsFillArrowRightCircleFill className={styles.arrows} />
                    </Link>
                </Col>
            </Row>
        </div>
    );
}
