import React from "react";
import { motion } from "framer-motion";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Hannah from "./images/Medium/hannah2.png";
import Lara from "./images/Medium/Lara2.png";
import Mum from "./images/Medium/mum2.png";
import Charmian from "./images/Medium/charmian5.png";
import JohnSam1 from "./images/Medium/johnsam1.png";
import JohnSam2 from "./images/Medium/johnsam2.png";
import styles from "./Packages.module.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import ListGroup from "react-bootstrap/ListGroup";

const products = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

export const Packages = () => (
  <div>
    <Container className={styles.Wrap}>
      <Row>
        <Col sm={12} md={6} lg={6} >
          <Container className={styles.Container}>
          <img className={styles.photo} src={Hannah}></img>
            <h1 className={styles.header}>Package 1 </h1>
            <div className={styles.text}>  - Headshot Session on location</div>
            <div className={styles.text}>  - 1 1/2 hours</div>
            <div className={styles.text}>  - 3 Retouched images</div>
            <div className={styles.text}>  - Extra images £20</div>
            <div className={styles.blank}>-</div>
            <h1 className={styles.price}> £130</h1>
            <div className={styles.text}>(Students £100)</div>
            </Container>
        </Col>
        <Col sm={12} md={6} lg={6}>
        <Container className={styles.Container}>
          <img className={styles.photo} src={Charmian}></img>
          <h1 className={styles.header}>Package 2 </h1>
          <div className={styles.text}>  - Headshot Session on location</div>
            <div className={styles.text}>  - Up to 3 hours</div>
            <div className={styles.text}>  - 3 Retouched images</div>
            <div className={styles.text}>  - Extra images £20</div>
            <div className={styles.blank}>-</div>
            <h1 className={styles.price}>£150</h1>
            <div className={styles.text}>(Students £130)</div>
            </Container>
        </Col>
        <Col sm={12} md={6} lg={6} >
        <Container className={styles.Container}>
          <img className={styles.photo} src={Lara}></img>
            <h1 className={styles.header}>Package 3 </h1>
            <div className={styles.text}>  - Headshot Session in Studio</div>
            <div className={styles.text}>  - 1 1/2 hours</div>
            <div className={styles.text}>  - 3 Retouched images</div>
            <div className={styles.text}>  - Extra images £20</div>
            <div className={styles.blank}>-</div>
            <h1 className={styles.price}> £140</h1>
            <div className={styles.text}>(Students £120)</div>
            </Container>
        </Col>
        <Col sm={12} md={6} lg={6}>
        <Container className={styles.Container}>
          <img className={styles.photo} src={Mum}></img>
          <h1 className={styles.header}>Package 4 </h1>
          <div className={styles.text}>  - Headshot Session in Studio</div>
            <div className={styles.text}>  - Up to 3 hours</div>
            <div className={styles.text}>  - 4 Retouched images</div>
            <div className={styles.text}>  - Extra images £20</div>
            <div className={styles.blank}>-</div>
            <h1 className={styles.price}>£160</h1>
            <div className={styles.text}>(Students £140)</div>
            </Container>
        </Col>
        <Col sm={12} md={6} lg={6}>
        <Container className={styles.Container}>
          <img className={styles.landscapePhoto} src={JohnSam1}></img>
          <h1 className={styles.header}>Package 5 </h1>
          <div className={styles.text}>  - Group Session on location</div>
            <div className={styles.text}>  - Up to 3 people</div>
            <div className={styles.text}>  - Up to 3 hours</div>
            <div className={styles.text}>  - 5 Retouched images</div>
            <div className={styles.text}>  - Extra images £30</div>
            <div className={styles.blank}>-</div>
            <h1 className={styles.price}>£215</h1>
            <div className={styles.text}>(Students £200)</div>
            </Container>
        </Col>
        <Col sm={12} md={6} lg={6}>
        <Container className={styles.Container}>
          <img className={styles.landscapePhoto} src={JohnSam2}></img>
          <h1 className={styles.header}>Package 6 </h1>
          <div className={styles.text}>  - Group Session in Studio</div>
            <div className={styles.text}>  - Up to 3 people</div>
            <div className={styles.text}>  - Up to 3 hours</div>
            <div className={styles.text}>  - 5 Retouched images</div>
            <div className={styles.text}>  - Extra images £30</div>
            <div className={styles.blank}>-</div>
            <h1 className={styles.price}>£230</h1>
            <div className={styles.text}>(Students £210)</div>
            </Container>
        </Col>
      </Row>
    </Container>
  </div>
);
