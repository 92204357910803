import React from 'react';
import styles from './Exhibitions.module.scss'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';


const data = {

  '2008': {
    title: '2008',
    subtitle: 'Circuit Wisely, Shoreditch Town Hall',
    sm: 12, md: 12, lg: 12
  },

  '2009': {
    title: '2009',
    subtitle: 'X Presents... Heart of Dixie UK',
    subtitle2: 'Goldsmiths Degree Show',
    sm: 12, md: 12, lg: 12
  },

  '2010': {
    title: '2010',
    subtitle: 'Circuit Wisely',
    text: 'Secret Location Dalston, London, Group Show',
    subtitle2: 'Silent City',
    text2: 'Part 1, The Rag Factory, London',
    sm: 12, md: 12, lg: 12

  },

  '2011': {
    title: '2011',
    subtitle: 'National Open Art Competition',
    text: 'Shortlisted',
    subtitle2: 'Open Studios',
    text2: 'Westland Place Studios',
    sm: 12, md: 12, lg: 12
  },

  '2012': {
    title: '2012',
    subtitle: 'Open Studios',
    text: 'Westland Place Studios',
    sm: 12, md: 12, lg: 12
  },

}

export const Exhibitions = (props) => (
  <div>
    <Container className={styles.gallery}>
      <Row>
        {Object.values(data).map(item => (
          <Col sm={item.sm} md={item.md} lg={item.lg}>
            <Container className={styles.textcontainers}>
              <div className={styles.title}>{item.title}</div>
              <div className={styles.subtitle}>{item.subtitle}</div>
              <div className={styles.text}>{item.text}</div>
            </Container>
            <Container className={styles.textcontainers}>
              <div className={styles.subtitle}>{item.subtitle2}</div>
              <div className={styles.text}>{item.text2}</div>
            </Container>
          </Col>
        ))}
      </Row>
    </Container>
  </div>
)
