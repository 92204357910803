import React from 'react';
import { FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { CgCopyright } from 'react-icons/cg';
import styles from './welcomeFooter.module.scss';
import { GrInstagram } from 'react-icons/gr';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export const welcomeFooter = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.Wrap}><div>
                <Container className={styles.footerContainer}>
                    <a className={styles.SocialIconLink1} href="//www.instagram.com/lara.cjohnsonp" target="_blank" aria-label="Instagram">
                        <GrInstagram />
                    </a>
                    <a className={styles.SocialIconLink2} href="//www.twitter.com/laraj_p" target="_blank" aria-label="Twitter">
                        <FaTwitter />
                    </a>
                    <a className={styles.SocialIconLink3} href="//www.linkedin.com/in/lara-johnson-perkins-054989209" target="_blank" aria-label="Instagram">
                        <FaLinkedin />
                    </a>
                </Container>
            </div>
            </div>
        </div>

    )
}

export default welcomeFooter
