import React from 'react';
import { motion } from "framer-motion"
import Card from 'react-bootstrap/Card'
import {
  BrowserRouter as Router,
  Link,
} from "react-router-dom";
import styles from "./Images.module.scss";
import ListGroup from 'react-bootstrap/ListGroup'
import Alert from 'react-bootstrap/Alert'
import Accordion from 'react-bootstrap/Accordion'

const products = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

export const Thankyou = () => (
  <motion.div exit={{ opacity: 0 }}>
        <motion.section
            variants={products}
            className="text-gray-700 body-font"
          >
    <div>
    <Card className="text-center">
  <Card.Body>
    <Card.Title>Thank you for your message!</Card.Title>
    <Card.Subtitle>
                  <Link className={styles.gallerybutton} to="/">back to home</Link>
                </Card.Subtitle>
  </Card.Body>
</Card>
    </div>
    </motion.section>
  </motion.div>

)