import React from 'react';
import { FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { CgCopyright } from 'react-icons/cg';
import styles from './Footer.module.scss';
import { GrInstagram } from 'react-icons/gr';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export const Footer = () => {
  return (
    <div className={styles.Footer}>
    <div className={styles.footerDiv1}>
    lara j-p | {""}{""}
      <CgCopyright  className={styles.copyRight}/>
    <div className={styles.Lara}>
    2022 All Rights Reserved
    </div>
    </div>
    <div className={styles.footerDiv}>
      Website created by Lara Johnson-Perkins
    </div>
  </div>

  )
}

export default Footer

